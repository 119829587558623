<div class="title">
  <h2>Be Social</h2>
</div>

<div class="social">
  <img src="/telegram.svg" alt="Telegram" />
  Want to chat and learn more about Clio? Join a community of Clio users and developers
  on <a href="https://t.me/clio_lang"> Telegram</a>!
</div>

<style>
  h2 {
    color: rgb(204 42 64);
    font-size: 3em;
    margin-bottom: 1em;
  }
  .title {
    max-width: 1280px;
    margin: 0 auto;
  }
  .social {
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: 4em;
    align-items: center;
  }
  .social img {
    height: 16px;
    margin-right: 0.5em;
  }
  a {
    color: rgb(33, 33, 33);
    border-bottom: 1px dotted rgba(33, 33, 33, 0.4);
    text-decoration: none;
    padding-bottom: 2px;
    margin-bottom: -3px;
    transition: cubic-bezier(0.23, 1, 0.32, 1) color 0.17s;
    margin-left: 0.2em;
  }
  a:hover {
    color: rgb(204, 42, 64);
    border-bottom: 2px solid rgb(204, 42, 64);
    padding-bottom: 2px;
    margin-bottom: -4px;
  }
  @media (max-width: 640px) {
    h2 {
      margin: 1em;
    }
    .social {
      padding: 1em;
      text-align: center;
    }
    .title {
      text-align: center;
    }
  }
</style>
